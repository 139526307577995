import React, { FC, useCallback, useMemo } from 'react';
import { useAddress, useConnectionStatus } from '@thirdweb-dev/react';
import {
  isWrap,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '@app/state/swapStore';
import { SwapField } from '@app/types/swap-field';
import { TradeState } from '@app/types/trade-state';
import { useApproveCallbackFromTrade } from '@app/hooks/v3/useApprove';
import {
  computeRealizedLPFeePercent,
  warningSeverity
} from '@app/helpers/swap/prices';
import { ApprovalState } from '@app/types/approve-state';
import { useSwapCallback } from '@app/hooks/v3/useSwapCallback';
import { SubmitButton } from '@app/components/SubmitButton';
import { CustomConnectWalletButton } from '@app/components/CustomConnectWalletButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { useWrapCallback } from '@app/hooks/v3/useWrapCallback';
import { useToggle } from 'react-use';
import { ConfirmModal } from '@app/components/ConfirmModal';
import { QueryKeys } from '@app/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useConfig } from '@app/config';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  onComplete?: (txHash?: string) => void;
}

export const SwapButton: FC<Props> = ({ onComplete }) => {
  const address = useAddress();
  const queryClient = useQueryClient();
  const [showImpactWarningModal, toggleShowImpactWarning] = useToggle(false);
  const status = useConnectionStatus();
  const isMismatchedNetwork = useNetworkMismatch();

  const { independentField } = useSwapState();
  const {
    tradeState,
    toggledTrade: trade,
    allowedSlippage,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo();
  const config = useConfig();
  const showWrap: boolean = isWrap(currencies, config);

  const parsedAmounts = useMemo(
    () => ({
      [SwapField.INPUT]:
        independentField === SwapField.INPUT
          ? parsedAmount
          : trade?.inputAmount,
      [SwapField.OUTPUT]:
        independentField === SwapField.OUTPUT
          ? parsedAmount
          : trade?.outputAmount
    }),
    [independentField, parsedAmount, trade]
  );

  const userHasSpecifiedInputOutput = Boolean(
    currencies[SwapField.INPUT] &&
      currencies[SwapField.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan('0')
  );

  const isExpertMode = true;
  const routeNotFound = !trade?.route;
  const isLoadingRoute = TradeState.LOADING === tradeState.state;

  const { priceImpact } = useMemo(() => {
    if (!trade) return { realizedLPFee: undefined, priceImpact: undefined };

    const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
    const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent);

    return { priceImpact };
  }, [trade]);

  const { approvalState, approvalCallback } = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact;

    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    );
  }, [priceImpact, trade]);

  const showApproveFlow =
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const {
    callback: swapCallback,
    error: swapCallbackError,
    isLoading: isSwapLoading
  } = useSwapCallback(trade, allowedSlippage, !showApproveFlow);

  const {
    callback: wrapCallback,
    error: wrapCallbackError,
    isLoading: isWrapLoading
  } = useWrapCallback(currencies, parsedAmount);

  const { onUserInput } = useSwapActionHandlers();

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(SwapField.INPUT, value);
    },
    [onUserInput]
  );

  const callback = showWrap ? wrapCallback : swapCallback;

  const handleSwap = useCallback(async () => {
    if (!callback) {
      return;
    }

    try {
      const res = await callback();

      if (res?.reason) {
        showErrorToast('Transaction rejected');
      }

      if (res?.receipt?.transactionHash) {
        await queryClient.invalidateQueries([
          QueryKeys.ELIGIBLE_TO_REFERRAL,
          address
        ]);
        handleTypeInput('');
        showSuccessToast(
          `Transaction successful`,
          <ExternalLink txHash={res?.receipt?.transactionHash} />
        );

        return res.receipt.transactionHash;
      }
    } catch (e: any) {
      showErrorToast(`Transaction failed: ${e.message}`);
    }
  }, [handleTypeInput, callback, address, queryClient]);

  const isValid = !swapInputError;
  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode; // const { priceImpact } = useMemo(() => {
  const priceImpactPercent = Number(priceImpact ? priceImpact.toFixed(2) : 0);
  const showImpactWarning = priceImpactPercent > 10;

  if (status !== 'connected') {
    return <CustomConnectWalletButton />;
  }

  if (isMismatchedNetwork) {
    return <NetworkMismatchButton />;
  }

  if (routeNotFound && userHasSpecifiedInputOutput && !showWrap)
    return (
      <SubmitButton
        isLoading={isLoadingRoute}
        disabled
        label="Insufficient liquidity for this trade."
      />
    );

  if (showApproveFlow) {
    return (
      <SubmitButton
        isLoading={approvalState === ApprovalState.PENDING}
        disabled={approvalState !== ApprovalState.NOT_APPROVED}
        onClick={() => approvalCallback && approvalCallback()}
        label={
          approvalState === ApprovalState.APPROVED
            ? 'Approved'
            : `Approve ${currencies[SwapField.INPUT]?.symbol}`
        }
      />
    );
  }

  return (
    <>
      <SubmitButton
        isLoading={isSwapLoading || isWrapLoading}
        onClick={async () => {
          if (showImpactWarning) {
            toggleShowImpactWarning(true);
          } else {
            const res = await handleSwap();

            if (onComplete && res) {
              onComplete(res);
            }
          }
        }}
        disabled={
          showWrap
            ? isSwapLoading
            : !isValid ||
              priceImpactTooHigh ||
              !!swapCallbackError ||
              isSwapLoading
        }
        label={swapInputError ?? 'Proceed to swap'}
      />
      <ConfirmModal
        isOpen={showImpactWarningModal}
        onClose={toggleShowImpactWarning}
        onSubmit={() => {
          toggleShowImpactWarning(false);
          handleSwap();
        }}
        title="Confirm swap"
        message="Price impact is greater than 10%. Are you sure you want to continue?"
      />
    </>
  );
};
