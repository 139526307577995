import { Button, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import fromExponential from 'from-exponential';
import { Currency } from '@cryptoalgebra/kim-sdk';

interface Props {
  onUpdate: (val: string) => void;
  balance: number | undefined;
  amount?: number | string;
  useThreshold?: boolean;
  onMax?: () => void;
}

export const QuickAmountButtons: FC<Props> = ({
  balance,
  onUpdate,
  amount,
  useThreshold,
  onMax
}) => {
  function renderButton(label: string, value: number, isMax?: boolean) {
    const isActive = amount && balance ? balance * value === +amount : false;

    return (
      <Button
        py="10px"
        px="12px"
        fontFamily="'Inter', sans-serif"
        fontSize="12px"
        lineHeight="18px"
        letterSpacing="-0.18px"
        w="100%"
        color={isActive ? 'white' : 'neutral.200'}
        borderRadius="8px"
        border="0.5px solid"
        borderColor="neutral.500"
        bg={isActive ? 'neutral.500' : 'neutral.700'}
        transition="color 0.2s ease"
        _hover={{ color: 'white' }}
        disabled={!balance}
        onClick={() => {
          if (isMax && onMax) {
            onMax();
          } else if (balance !== undefined) {
            onUpdate(fromExponential((balance * value).toString()));
          }
        }}
      >
        {label}
      </Button>
    );
  }

  return (
    <HStack w="100%" mt="8px">
      {renderButton('25%', 0.25)}
      {renderButton('50%', 0.5)}
      {renderButton('75%', 0.75)}
      {renderButton('100%', useThreshold ? 0.99 : 0.999999, !!onMax)}
    </HStack>
  );
};
